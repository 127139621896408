.containerAlternative {
  // margin: 10px 30px;
  padding: 20px 30px;
  background: #ffffff;
  width: 100%;

  .features {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    .featuresForm {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .featuresItem {
        width: 200px;
        margin-right: 20px;
      }
    }

    .featuresButton {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .featuresButtonItem {
        margin-right: 20px;
      }
    }
  }

  .subFeatures {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .subFeaturesItem {
      margin-right: 7px;
    }
  }

  .paginationForTable {
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 100%;
  }

  .mainTable {
    margin-top: 10px;
  }
}
