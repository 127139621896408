body{
    // overflow: scroll;
}
#grid-view-device {
    padding-bottom: 200px;

    .row {
        margin-right: -5px;
        margin-left: -5px;

        [class*="col-"] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .view-device-item {
        display: flex;
        flex-direction: column;
        border: 1px solid #f0f2f5;
        cursor: pointer;
        height: 100%;
        position: relative;

        &.active {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.4);
            }
        }

        .view-device-head {
            background-color: rgba(0, 0, 0, 0.65);
            color: #fff;
            text-align: center;
            padding: 10px;
        }

        .view-device-img {
            padding-top: 160%;
            position: relative;
            background-color: #f0f2f5;

            .ant-image {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .view-device-info {
            padding: 10px;

            p {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.grid-icon-all {

    .grid-icon {
        display: inline-block;
    }

    .grid-icon:hover>span>span {
        opacity: .8;
    }

    .grid-icon>.layer {
        width: 100%;
        position: absolute;
        left: 0;
    }

    /* style */

    .grid-icon--line {
        position: relative;
        width: 19px;
        height: 19px;
        top: 2px;
    }

    .grid-icon--line>.layer {
        height: 7px;
    }

    .grid-icon--line>.layer--primary {
        top: 0;
    }

    .grid-icon--line>.layer--secondary {
        top: 50%;
        margin-top: -4px;
    }

    .grid-icon--line>.layer--tertiary {
        bottom: 0;
    }

    .grid-icon--line>.layer>span {
        position: absolute;
        display: block;
        width: 7px;
        height: 100%;
        box-sizing: border-box;
        border: solid 2px #333;
    }

    .grid-icon--line>.layer>span:first-child {
        left: 0;
    }

    .grid-icon--line>.layer>span:nth-child(2) {
        left: 50%;
        margin-left: -4px;
    }

    .grid-icon--line>.layer>span:last-child {
        right: 0;
    }
    .ant-radio-button-wrapper-checked{
        .grid-icon--line>.layer>span{
            border-color: #40a9ff
        }
    }
}
.ant-modal-header{
    border-bottom: 1px solid #e4e7ed;
    padding-bottom: 3px;
    margin-bottom: 22px!important;
}