.containerAlter {
  display: flex;

  .containerFolder {
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 20px 30px;
    background: #ffffff;
    width: 20%;
    // position: fixed;
    .features {
      .buttonContainer {
        display: flex;
        .buttonContainerBtn {
          margin-right: 7px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          background-color: #f8f9fa;
          border: none;
        }
      }
    }

    .folderIdContainer {
      margin-top: 15px;
    }

    .folderListContainer {
      margin-top: 15px;
    }
  }

  .containerContent {
    background: #ffffff;
    padding: 20px 30px;
    width: 80%;

    .contentFeatures {
      display: flex;
      align-items: center;
      width: 100%;

      .contentFeaturesForm {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .contentFeaturesBtnCon {
          display: flex;
          width: 30%;
          max-width: 300px;
          justify-content: space-between;
          .contentFeaturesBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-right: 5px;
          }
        }
      }
      // .contentFilter {
      //   display: flex;
      //   justify-content: flex-end;
      //   align-items: flex-end;
      // }
    }

    .contentTable {
      .contentTablePagination {
        margin-bottom: 20px;
      }
    }
  }
}
